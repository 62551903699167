<template>
  <div
    class="trade"
    :class="headerClass"
    data-epica-module-name="Banner Indicadores"
  >
    <div class="wrap-trade">
      <div
        class="playSlideQuotes"
        @click="toggleQuoteBanner()"
        v-if="user && user.isSubscriber"
      >
        <i
          v-bind:class="{
            'icon-play_arrow': !isPlayingQuoteBanner,
            'icon-pause': isPlayingQuoteBanner,
          }"
        ></i>
      </div>
      <div class="quote-banner-container">
        <div
          class="quote-banner"
          @mouseover="overQuoteBanner()"
          @mouseleave="leaveQuoteBanner()"
        >
          <ul class="list-first">
            <li v-for="quote in quotes" :key="quote.id">
              <quote-header :quote="quote" />
            </li>
          </ul>
          <ul class="list-second">
            <li v-for="quote in quotes" :key="quote.id">
              <quote-header :quote="quote" />
            </li>
          </ul>
        </div>
      </div>
      <a
        class="ancorQuotes"
        href="/indicadores-economicos"
        v-if="user && user.isSubscriber"
      >
        VER MÁS
      </a>
      <a
        class="subscriber-button"
        target="_blank"
        rel="nofollow"
        href="https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica"
        v-else
      >
        Suscríbase
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QuoteHeader from "../quote-header/QuoteHeader.vue";
import epica from "../../../../../lr/js/features/epica";
export default {
  components: { QuoteHeader },
  props: {
    headerClass: String,
  },
  created: function () {
    this.init();
  },
  data: function () {
    return {
      isPlayingQuoteBanner: false,
    };
  },
  updated: function () {
    epica("#vue-header");
  },
  computed: {
    ...mapGetters("master", {
      quotes: "bannerQuotes",
    }),
    ...mapGetters("auth", {
      user: "user",
    }),
  },
  methods: {
    init() {
      setTimeout(() => {
        if (this.quotes) {
          this.toggleQuoteBanner();
        } else {
          this.init();
        }
      }, 1500);
    },
    stopQuoteBanner: function () {
      clearTimeout(qTimeoutId);
      const ll = ComputeLeft();
      const _firstList = document.querySelectorAll(".list-first")[0];
      const _secondList = document.querySelectorAll(".list-second")[0];
      _firstList.classList.remove("move-list-first");
      _secondList.classList.remove("move-list-second");
      _firstList.style.left = ll + "px";
      _secondList.style.left = ll + "px";
    },
    startQuoteBanner: function () {
      const ll = ComputeLeft();
      const _firstList = document.querySelectorAll(".list-first")[0];
      const _secondList = document.querySelectorAll(".list-second")[0];
      _firstList.classList.add("move-list-first");
      _secondList.classList.add("move-list-second");
      _firstList.style.left = ll + "px";
      _secondList.style.left = ll + "px";
      removeLeft(ll);
    },
    overQuoteBanner: function () {
      if (this.isPlayingQuoteBanner) {
        this.stopQuoteBanner();
      }
    },
    leaveQuoteBanner: function () {
      if (this.isPlayingQuoteBanner) {
        this.startQuoteBanner();
      }
    },
    toggleQuoteBanner: function () {
      this.isPlayingQuoteBanner = !this.isPlayingQuoteBanner;
      if (this.isPlayingQuoteBanner) {
        this.startQuoteBanner();
      } else {
        this.stopQuoteBanner();
      }
    },
  },
};

let qTimeoutId = null;
const ComputeLeft = () => {
  const l1 =
    document.querySelectorAll(".list-first")[0].getBoundingClientRect().x -
    document
      .querySelectorAll(".quote-banner-container")[0]
      .getBoundingClientRect().x;
  const l2 =
    document.querySelectorAll(".list-second")[0].getBoundingClientRect().x -
    document
      .querySelectorAll(".quote-banner-container")[0]
      .getBoundingClientRect().x;
  return l1 < 0 && l2 < 0 ? (l1 < l2 ? l2 : l1) : l1 > l2 ? l2 : l1;
};

const removeLeft = (ll) => {
  const _firstList = document.querySelectorAll(".list-first")[0];
  const _secondList = document.querySelectorAll(".list-second")[0];
  const x = _firstList.getBoundingClientRect().width;
  const seconds = ((x + ll) / x) * 50;
  qTimeoutId = setTimeout(function () {
    _firstList.classList.remove("move-list-first");
    _secondList.classList.remove("move-list-second");
    _firstList.style.left = null;
    _secondList.style.left = null;
    setTimeout(function () {
      _firstList.classList.add("move-list-first");
      _secondList.classList.add("move-list-second");
    }, 100);
  }, seconds * 1000);
};
</script>
