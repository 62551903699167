<template>
  <div class="cookies" v-if="!hideBanner">
    <div class="title">COOKIES</div>
    <p>
      Este sitio web usa cookies propias y de terceros para analizar el tráfico,
      mejorar nuestros servicio y conocer el comportamiento del usuario. Para
      conocer más, acceda a nuestra
      <a :href="cookiesPolicyUrl">Política de Cookies</a>.
    </p>
    <span class="action" @click="hasAceptedCookiesPolicy()">ENTENDIDO</span>
  </div>
</template>
<script>
export default {
  props: {
    cookiesPolicyUrl: String,
  },
  mounted() {
    this.cookieRef = "LR_hacp=true";
    document.addEventListener("scroll", this.validateCookieStatus, false);
  },
  methods: {
    hasAceptedCookiesPolicy() {
      document.cookie = this.cookieRef + "; max-age=31536000; samesite=strict";
      this.hideBanner = true;
    },
    validateCookieStatus() {
      this.hideBanner = document.cookie.indexOf(this.cookieRef) >= 0;
      document.removeEventListener("scroll", this.validateCookieStatus, false);
    },
  },
  data: function () {
    return {
      hideBanner: true,
    };
  },
};
</script>