var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "trade",
      class: _vm.headerClass,
      attrs: { "data-epica-module-name": "Banner Indicadores" },
    },
    [
      _c("div", { staticClass: "wrap-trade" }, [
        _vm.user && _vm.user.isSubscriber
          ? _c(
              "div",
              {
                staticClass: "playSlideQuotes",
                on: {
                  click: function ($event) {
                    return _vm.toggleQuoteBanner()
                  },
                },
              },
              [
                _c("i", {
                  class: {
                    "icon-play_arrow": !_vm.isPlayingQuoteBanner,
                    "icon-pause": _vm.isPlayingQuoteBanner,
                  },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "quote-banner-container" }, [
          _c(
            "div",
            {
              staticClass: "quote-banner",
              on: {
                mouseover: function ($event) {
                  return _vm.overQuoteBanner()
                },
                mouseleave: function ($event) {
                  return _vm.leaveQuoteBanner()
                },
              },
            },
            [
              _c(
                "ul",
                { staticClass: "list-first" },
                _vm._l(_vm.quotes, function (quote) {
                  return _c(
                    "li",
                    { key: quote.id },
                    [_c("quote-header", { attrs: { quote: quote } })],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "list-second" },
                _vm._l(_vm.quotes, function (quote) {
                  return _c(
                    "li",
                    { key: quote.id },
                    [_c("quote-header", { attrs: { quote: quote } })],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.user && _vm.user.isSubscriber
          ? _c(
              "a",
              {
                staticClass: "ancorQuotes",
                attrs: { href: "/indicadores-economicos" },
              },
              [_vm._v("\n      VER MÁS\n    ")]
            )
          : _c(
              "a",
              {
                staticClass: "subscriber-button",
                attrs: {
                  target: "_blank",
                  rel: "nofollow",
                  href: "https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica",
                },
              },
              [_vm._v("\n      Suscríbase\n    ")]
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }