import { render, staticRenderFns } from "./LiveBanner.vue?vue&type=template&id=1cd5a3c5&"
import script from "./LiveBanner.vue?vue&type=script&lang=js&"
export * from "./LiveBanner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\larepublica\\lr-statics\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1cd5a3c5')) {
      api.createRecord('1cd5a3c5', component.options)
    } else {
      api.reload('1cd5a3c5', component.options)
    }
    module.hot.accept("./LiveBanner.vue?vue&type=template&id=1cd5a3c5&", function () {
      api.rerender('1cd5a3c5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lrm/js/vue/components/live-banner/LiveBanner.vue"
export default component.exports