<template>
  <div class="user-login" v-click-outside="hideMenu">
    <button
      class="login-btn"
      @click="toggleMenu()"
      :class="{ active: menuIsVisible }"
      :title="user ? user.completeName : 'Ingrese o Regístrese'"
    >
      <i class="icon-user"></i>
    </button>
    <div class="modal" v-if="menuIsVisible">
      <template v-if="hasLoaded">
        <template v-if="user">
          <div class="user-drop-menu">
            <span
              class="user"
              :title="user.completeName"
              v-html="user.printName"
            ></span>
            <div class="line"></div>
            <div class="profile">
              <a href="//auth.lalr.co"><i class="icon-user"></i>VER PERFÍL</a>
            </div>
            <div
              class="success-message"
              v-if="successMessage"
              v-html="successMessage"
            ></div>
            <div class="content-suscriptor" v-if="!user.isSubscriber">
              <template v-if="!hasSubscriberId">
                <span
                  >Si ya es suscriptor de La República, ingrese su cédula o
                  NIT</span
                >
                <form @submit.prevent="subscribe">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="id"
                      autocomplete="off"
                      placeholder="Ingrese su identificación"
                    />
                    <button id="documento">
                      <i class="icon-check-circle"></i>
                    </button>
                  </div>
                  <span
                    v-if="subscribeMessage"
                    class="error"
                    v-html="subscribeMessage"
                  ></span>
                </form>
                <span>O puede <strong>comprar</strong> una suscripción</span>
                <a
                  href="https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica"
                  target="_blank"
                  rel="noopener"
                  class="suscribe"
                  >SUSCRÍBASE</a
                >
              </template>
              <template v-else>
                <p>
                  Lo sentimos, el número de identificación relacionado con su
                  cuenta no tiene una suscripción activa.
                </p>
                <p>
                  Puede comprar su suscripción aquí o renovarla telefónicamente
                  al.
                </p>
                <strong>(+57-1) 422 7600 Opción: 1-1-2</strong>
                <a
                  href="https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica"
                  target="_blank"
                  rel="noopener"
                  class="suscribe"
                  >COMPRAR SUSCRIPCIÓN</a
                >
              </template>
            </div>
            <button @click="logout()" class="logout">
              CERRAR SESIÓN<i class="icon-exit"></i>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="login">
            <button @click="login()">
              <i class="icon-user"></i>INICIAR SESIÓN
            </button>
          </div>
          <div class="registre">
            <button @click="register()">
              <i class="icon-user-add"></i>REGÍSTRESE
            </button>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="loading"></div>
      </template>
    </div>
    <div
      class="overlay"
      @click="hideMenu()"
      v-bind:class="{ show: menuIsVisible }"
    ></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { clickOutside } from "../../../../../lr/js/vue/directives/lr-directives";

clickOutside();
export default {
  watch: {
    menuIsVisible: function (newVal, oldVal) {
      if (newVal) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
  data: function () {
    return {
      menuIsVisible: false,
      id: null,
      subscribeMessage: null,
      successMessage: null,
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
      hasLoaded: "hasLoaded",
      token: "token",
    }),
    hasSubscriberId() {
      return this.user && this.user.subscriberId;
    },
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      register: "auth/register",
      logout: "auth/logout",
      subscribeUser: "auth/subscribeUser",
    }),
    hideMenu: function () {
      if (this.menuIsVisible) {
        this.menuIsVisible = false;
      }
    },
    toggleMenu: function () {
      this.menuIsVisible = !this.menuIsVisible;
    },
    subscribe: function (e) {
      if (this.id) {
        const _self = this;
        _self.subscribeMessage = null;
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };
        axios
          .post(
            "/api/lrplus/subscribe",
            {
              identification: this.id,
            },
            axiosConfig
          )
          .then(function (d) {
            if (d.data.success) {
              _self.subscribeUser(_self.id);
              _self.successMessage = "Cuenta activada de forma satisfactoria.";
              setTimeout(() => {
                _self.successMessage = null;
              }, 3000);
            } else {
              _self.subscribeMessage = d.data.errorMessage;
            }
          });
      } else {
        const _self = this;
        _self.subscribeMessage = "El número de identificación es obligatorio";
        setTimeout(() => {
          _self.subscribeMessage = null;
        }, 3000);
      }
    },
  },
};
</script>
