<template>
    <a href="/elecciones-territoriales-2023" class="elections-2023-banner-lite" v-if="title && !liveOn">
        <div v-if="!hideTitle" class="logo-title"
            style="background-image: url('https://s.lalr.co/__assets/lrm/images/elecciones-2023/banner-elecciones-2023.jpg')">
            <img src="https://s.lalr.co/__assets/lrm/images/elecciones-2023/logo-elecciones-2023.svg"
                alt="Elecciones Territoriales 2023">
            <span class="title" v-html="title"></span>
        </div>

        <div class="bulletin" v-if="bulletin">
            <i class="icon-bell-circle"></i>
            <div v-html="bulletin"></div>
        </div>
    </a>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
    created() {
        this.$refreshHub.$on("banner-elections", this.newInfo);
        this.loadBanner();
    },
    computed: {
        ...mapGetters("live", {
            liveOn: "isOn"
        }),
    },
    methods: {
        controlHeader: function () {
            const isElections = document.querySelector("#vue-container .homeElecciones, #vue-container .detail-elections");
            if (isElections) {
                this.hideTitle = true;
            } else {
                var cSheetElec = document.createElement('style')
                cSheetElec.innerHTML = `#vue-container:not(.live-on) {
                    margin-top: 156px;
                }
                #vue-container:not(.live-on) .indicadoresSect .menuQuote,
                #vue-container:not(.live-on) .InternaIndicadores .menuQuote {
                    margin-top: 58px;
                }`;
                document.body.appendChild(cSheetElec);
            }
        },
        newInfo: function (data) {
            var _self = this;
            _self.bulletin = data;
            if (this.toId) {
                clearTimeout(this.toId);
            }
            this.toId = setTimeout(() => {
                _self.bulletin = null;
            }, 30000);
        },
        loadBanner: function () {
            this.loading = true;
            var _self = this;
            axios
                .get("/api/elections/2023/banner")
                .then(function (d) {
                    _self.loading = false;
                    if (d.data.success && d.data.opening.feedTitle) {
                        _self.title = d.data.opening.feedTitle;
                        _self.controlHeader();
                    }
                });
        }
    },
    data: function () {
        return {
            bulletin: null,
            title: null,
            loading: false,
            hideTitle: false,
            toId: null
        };
    },
};
</script>