<template>
  <a
    href="/en-vivo"
    class="live show"
    v-if="live"
    data-mrf-recirculation="Banner En Vivo"
  >
    <div class="icon-live">
      <img src="https://s.lalr.co/lr/images/icon-live-gif.gif" alt="live" />
      <span>EN VIVO</span>
      <div class="line"></div>
    </div>
    <div class="wrap-content">
      <div class="content">
        <img
          class="img-inside"
          v-if="live.isInside"
          src="https://s.lalr.co/lr/images/logos/logo-Inside.png"
        />
        <!-- <span
        class="type"
        v-if="!live.isInside"
        v-html="live.principalTitle"
      ></span> -->
        <span class="tittle" v-html="live.title"></span>
      </div>
    </div>
  </a>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    loadLive: Boolean,
  },
  created: function () {
    this.init();
    if (this.loadLive) {
      this.getLive();
    }
  },
  computed: {
    ...mapGetters("live", {
      live: "data",
    }),
  },
  methods: {
    ...mapActions("live", {
      init: "initLite",
      getLive: "getLiveLite",
    }),
  },
};
</script>
