var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideMenu,
          expression: "hideMenu",
        },
      ],
      staticClass: "menu-lr-mas",
    },
    [
      _c(
        "button",
        {
          class: { active: _vm.menuIsVisible },
          on: {
            click: function ($event) {
              return _vm.toggleMenu()
            },
          },
        },
        [_c("i", { staticClass: "icon-menu-puntos" })]
      ),
      _vm._v(" "),
      _vm.menuIsVisible
        ? _c("div", { staticClass: "modal" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "line" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row row-cols-3 justify-content-center" },
              [
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _vm._m(4),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _vm._m(6),
                _vm._v(" "),
                _vm._m(7),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  !_vm.user ||
                  (!_vm.user.isSubscriber && !_vm.user.hasActivedCourtesy)
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: "https://mas.larepublica.co/cortesias",
                            target: "_blank",
                          },
                        },
                        [
                          _c("i", { staticClass: "icon-courtesy" }),
                          _vm._v("ACTIVAR CORTESÍA\n        "),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  !_vm.user || !_vm.user.hasActivatedSubscriberId
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: "https://mas.larepublica.co/activar-suscripcion",
                            target: "_blank",
                          },
                        },
                        [
                          _c("i", { staticClass: "icon-user-active" }),
                          _vm._v("ACTIVAR SUSCRIPCIÓN\n        "),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm._m(8),
              ]
            ),
            _vm._v(" "),
            _vm.user && !_vm.user.isSubscriber
              ? _c(
                  "div",
                  { staticClass: "content-suscriptor" },
                  [
                    _c("div", { staticClass: "line" }),
                    _vm._v(" "),
                    !_vm.hasSubscriberId
                      ? [
                          _c("span", [
                            _vm._v(
                              "Si ya es suscriptor de La República, ingrese su cédula o NIT"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.subscribe.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "input-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.id,
                                      expression: "id",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    autocomplete: "off",
                                    placeholder: "Ingrese su identificación",
                                  },
                                  domProps: { value: _vm.id },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.id = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm._m(9),
                              ]),
                              _vm._v(" "),
                              _vm.subscribeMessage
                                ? _c("span", {
                                    staticClass: "error",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.subscribeMessage),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(10),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "suscribe",
                              attrs: {
                                href: "https://suscripciones.larepublica.co/",
                                target: "_blank",
                                rel: "noopener",
                              },
                            },
                            [_vm._v("SUSCRÍBASE")]
                          ),
                        ]
                      : [
                          _c("p", [
                            _vm._v(
                              "\n          Lo sentimos, el número de identificación relacionado con su cuenta\n          no tiene una suscripción activa.\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n          Puede comprar su suscripción aquí o renovarla telefónicamente al.\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("strong", [
                            _vm._v("(+57-1) 422 7600 Opción: 1-1-2"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "suscribe",
                              attrs: {
                                href: "https://suscripciones.larepublica.co/",
                                target: "_blank",
                                rel: "noopener",
                              },
                            },
                            [_vm._v("COMPRAR SUSCRIPCIÓN")]
                          ),
                        ],
                  ],
                  2
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "overlay",
        class: { show: _vm.menuIsVisible },
        on: {
          click: function ($event) {
            return _vm.hideMenu()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "https://mas.larepublica.co/", target: "_blank" } },
      [_c("i", { staticClass: "icon-lrmas" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://mas.larepublica.co/noticias",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "icon-noticias" }),
          _vm._v("Mi Selección de noticias\n        "),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://mas.larepublica.co/10000-empresas",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "icon-empresas" }),
          _vm._v("BITÁCORA EMPRESARIAL 10.000 LR\n        "),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://mas.larepublica.co/eventos",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "icon-eventos" }),
          _vm._v("Centro de Convenciones\n        "),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://mas.larepublica.co/libros",
            target: "_blank",
          },
        },
        [_c("i", { staticClass: "icon-libros" }), _vm._v("KIOSCO\n        ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://mas.larepublica.co/version-digital",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "icon-version-digital" }),
          _vm._v("Tinta Digital\n        "),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://mas.larepublica.co/articulos-guardados",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "icon-favoritos" }),
          _vm._v("BIBLIOTECA PERSONAL\n        "),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://mas.larepublica.co/notificaciones",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "icon-notificacion" }),
          _vm._v("Notificaciones y alertas\n        "),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col full" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://mas.larepublica.co/historia-economica",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "icon-book-economy" }),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "n" }, [_vm._v("Nuevo")]),
            _vm._v("\n            HISTORIA ECONÓMICA DE COLOMBIA\n          "),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { attrs: { id: "documento" } }, [
      _c("i", { staticClass: "icon-check-circle" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("O puede "),
      _c("strong", [_vm._v("comprar")]),
      _vm._v(" una suscripción"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }