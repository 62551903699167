var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.title && !_vm.liveOn
    ? _c(
        "a",
        {
          staticClass: "elections-2023-banner-lite",
          attrs: { href: "/elecciones-territoriales-2023" },
        },
        [
          !_vm.hideTitle
            ? _c(
                "div",
                {
                  staticClass: "logo-title",
                  staticStyle: {
                    "background-image":
                      "url('https://s.lalr.co/__assets/lrm/images/elecciones-2023/banner-elecciones-2023.jpg')",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://s.lalr.co/__assets/lrm/images/elecciones-2023/logo-elecciones-2023.svg",
                      alt: "Elecciones Territoriales 2023",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "title",
                    domProps: { innerHTML: _vm._s(_vm.title) },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.bulletin
            ? _c("div", { staticClass: "bulletin" }, [
                _c("i", { staticClass: "icon-bell-circle" }),
                _vm._v(" "),
                _c("div", { domProps: { innerHTML: _vm._s(_vm.bulletin) } }),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }