import Vue from 'vue';
import {
    mapActions,
    mapGetters
} from 'vuex';
import SearchBox from './../components/search-box/SearchBox.vue';
import LiveBanner from './../components/live-banner/LiveBanner.vue';
import QuoteHeaderBanner from './../components/quote-header-banner/QuoteHeaderBanner.vue';
import UserHeaderButton from './../components/user-header-button/UserHeaderButton.vue';
import MenuLrPlusHeader from './../components/menu-lr-plus-header/MenuLrPlusHeader.vue';
import BannerElections2023 from './../components/banner-elections-2023/BannerElections2023.vue';
import epica from '../../../../lr/js/features/epica';
import {
    helpers
} from '../../../../lr/js/features/epica';
import store from '../../../../lr/js/vue/store';

export const header = (mixins) => {
    helpers();
    const headerSelector = '#vue-header';
    mixins = mixins ? mixins : [{
        created() {
            this.userBasic();
        }
    }];
    new Vue({
        store,
        el: headerSelector,
        mixins: mixins,
        created: function () {
            this.masterInit();
        },
        components: {
            SearchBox,
            LiveBanner,
            QuoteHeaderBanner,
            MenuLrPlusHeader,
            UserHeaderButton,
            BannerElections2023
        },
        computed: {
            ...mapGetters('auth', {
                user: 'user',
                hasLoaded: 'hasLoaded'
            })
        },
        data: function () {
            return {
                isMenuShow: false,
                searchBoxIsOpen: false

            };
        },
        watch: {
            isMenuShow: function (newVal, oldVal) {
                if (newVal) {
                    document.body.classList.add("overflow-hidden");
                } else {
                    document.body.classList.remove("overflow-hidden");
                }
            },
            searchBoxIsOpen: function (newVal, oldVal) {
                if (newVal) {
                    document.body.classList.add("overflow-hidden");
                } else {
                    document.body.classList.remove("overflow-hidden");
                }
            },
        },
        mounted: function () {
            this.validateHasLoaded();
        },
        methods: {
            ...mapActions({
                login: 'auth/login',
                logout: 'auth/logout',
                init: 'auth/init',
                userBasic: 'auth/getUserBasic',
                userNews: 'auth/getUserNews',
                masterInit: 'master/init'
            }),
            closeMenu: function () {
                this.isMenuShow = false;
            },
            showMenu: function () {
                this.isMenuShow = true;
            },
            validateHasLoaded: function(){
                const _self = this;
                if (_self.hasLoaded) {
                    epica(headerSelector);
                } else{
                    setTimeout(() => {
                        _self.validateHasLoaded();
                    }, 400);
                }
            }
        }
    });
}