<template>
  <div class="search-box-modal row" v-if="isOpen">
    <div class="search-header" data-epica-module-name="Header">
      <button class="close" type="button" @click="close">
        <i class="icon-close"></i>
      </button>
      <a href="/" class="icon-logo-base-lr-small"></a>
      <form action="/buscar">
        <div class="grid-x">
          <div class="auto">
            <input
              ref="topic"
              name="term"
              type="text"
              placeholder="Buscar en La República"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              v-on:keydown.up.prevent="inputUp($event)"
              v-on:keydown.down.prevent="inputDown($event)"
              v-on:keydown.enter.prevent="inputEnter"
              :value="topic"
              @input="(evt) => (topic = evt.target.value)"
            />
          </div>
          <div class="shrink">
            <button ref="dosearch" class="search" :disabled="!topic">
              <i class="icon-search"></i>
            </button>
          </div>
        </div>
        <div class="auto">
          <button type="button" class="cancel" @click="close">Cancelar</button>
        </div>
      </form>
      <div
        class="autocomplete-results"
        v-if="acResult"
        data-epica-module-name="Autocomplete Caja Búsqueda"
        data-mrf-recirculation="Autocomplete Caja Búsqueda"
      >
        <div v-if="acResult.tag && acResult.tag.length" class="contentType">
          <div class="grid-x">
            <div class="icon">
              <i class="icon-tag"></i>
            </div>
            <div>
              <h3>
                <span>Temas</span>
              </h3>
              <div class="wrap-items">
                <div v-for="(item, index) in acResult.tag" :key="index">
                  <a
                    ref="result"
                    :href="item.url"
                    v-html="item.title"
                    v-bind:class="{ focus: item.idx == focus }"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="acResult.author && acResult.author.length"
          class="contentType"
        >
          <div class="grid-x">
            <div class="icon">
              <i class="icon-person"></i>
            </div>
            <div>
              <h3>
                <span>Autores</span>
              </h3>
              <div class="wrap-items">
                <div v-for="(item, index) in acResult.author" :key="index">
                  <a
                    ref="result"
                    :href="item.url"
                    v-html="item.title"
                    v-bind:class="{ focus: item.idx == focus }"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="acResult.inside && acResult.inside.length"
          class="contentType article"
        >
          <div class="grid-x">
            <div class="icon">
              <i class="icon-videocam"></i>
            </div>
            <div>
              <h3>
                <span>Inside</span>
              </h3>
              <div class="wrap-items">
                <div v-for="(item, index) in acResult.inside" :key="index">
                  <a
                    ref="result"
                    :href="item.url"
                    v-html="item.title"
                    v-bind:class="{ focus: item.idx == focus }"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="acResult.podcast && acResult.podcast.length"
          class="contentType"
        >
          <div class="grid-x">
            <div class="icon">
              <i class="icon-mic"></i>
            </div>
            <div>
              <h3>
                <span>Podcast</span>
              </h3>
              <div v-for="(item, index) in acResult.podcast" :key="index">
                <a
                  ref="result"
                  :href="item.url"
                  v-html="item.title"
                  v-bind:class="{ focus: item.idx == focus }"
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="acResult.post && acResult.post.length"
          class="contentType article"
        >
          <div class="grid-x">
            <div class="icon">
              <i class="icon-file-text"></i>
            </div>
            <div>
              <h3>
                <span>Artículos</span>
              </h3>
              <div v-for="(item, index) in acResult.post" :key="index">
                <a
                  ref="result"
                  :href="item.url"
                  v-html="item.title"
                  v-bind:class="{ focus: item.idx == focus }"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from "axios";
import epica from "../../../../../lr/js/features/epica";
export default {
  props: {
    isOpen: Boolean,
  },
  mounted: function () {
    document.addEventListener("keyup", this.keyup);
  },
  watch: {
    topic: function (newVal, oldVal) {
      this.autocomplete();
    },
  },
  updated: function () {
    if (this.isOpen) {
      this.$nextTick(() => this.$refs.topic.focus());
      epica(".search-box-modal");
    }
  },
  methods: {
    inputUp: function (e) {
      e.target.setSelectionRange;
      if (this.acResult && this.focus != -1) {
        this.focus--;
      }
      if (this.focus > -1) {
        this.topic = this.acAll[this.focus].rawTitle;
      } else {
        this.topic = this.tmpTopic;
      }
    },
    inputDown: function (e) {
      if (this.acResult) {
        this.focus =
          this.focus == this.totalResults - 1 ? this.focus : this.focus + 1;
        this.topic = this.acAll[this.focus].rawTitle;
      }
    },
    inputEnter: function () {
      if (this.focus > -1) {
        for (const key in this.acResult) {
          if (this.acResult.hasOwnProperty(key)) {
            const el = this.acResult[key];
            for (let index = 0; index < el.length; index++) {
              if (el[index].idx == this.focus) {
                document.location.href = el[index].url;
                return;
              }
            }
          }
        }
      } else {
        this.$refs.dosearch.click();
      }
    },
    keyup: function (event) {
      if (event.isComposing || event.keyCode === 229) {
        return;
      }
      const _self = this;
      if (this.isOpen) {
        if (event.keyCode == 27) {
          this.close();
        }
      } else {
        if (event.keyCode == 66 && event.altKey) {
          this.open();
        }
      }
    },
    autocomplete: debounce(function () {
      this.searching = true;
      const _self = this;
      this.focus = -1;
      this.totalResults = -1;
      this.acAll = [];
      this.tmpTopic = this.topic;
      if (this.topic) {
        const params = {
          term: this.topic,
        };
        axios.get("/api/ac", { params }).then(function (d) {
          let idx = 0;
          const dat = {
            tag: d.data.tag.map((q) => {
              q.idx = idx++;
              _self.acAll.push(q);
              return q;
            }),
            author: d.data.author.map((q) => {
              q.idx = idx++;
              _self.acAll.push(q);
              return q;
            }),
            inside: d.data.inside.map((q) => {
              q.idx = idx++;
              _self.acAll.push(q);
              return q;
            }),
            podcast: d.data.podcast.map((q) => {
              q.idx = idx++;
              _self.acAll.push(q);
              return q;
            }),
            post: d.data.post.map((q) => {
              q.idx = idx++;
              _self.acAll.push(q);
              return q;
            }),
          };
          _self.totalResults = idx;
          _self.acResult = dat;
          _self.searching = false;
        });
      } else {
        this.clean();
      }
    }, 250),
    close: function () {
      this.clean();
      this.$emit("closed");
    },
    open: function () {
      this.$emit("opened");
    },
    clean: function () {
      this.topic = null;
      this.tmpTopic = null;
      this.acResult = null;
      this.acAll = null;
    },
  },
  data: function () {
    return {
      topic: null,
      acResult: null,
      focus: -1,
    };
  },
};
</script>
