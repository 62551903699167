var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.live
    ? _c(
        "a",
        {
          staticClass: "live show",
          attrs: {
            href: "/en-vivo",
            "data-mrf-recirculation": "Banner En Vivo",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "wrap-content" }, [
            _c("div", { staticClass: "content" }, [
              _vm.live.isInside
                ? _c("img", {
                    staticClass: "img-inside",
                    attrs: {
                      src: "https://s.lalr.co/lr/images/logos/logo-Inside.png",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", {
                staticClass: "tittle",
                domProps: { innerHTML: _vm._s(_vm.live.title) },
              }),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-live" }, [
      _c("img", {
        attrs: {
          src: "https://s.lalr.co/lr/images/icon-live-gif.gif",
          alt: "live",
        },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("EN VIVO")]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }